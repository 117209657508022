import React from "react"
import { FormattedMessage } from "react-intl"
import SubLayout from "../components/Sublayout"
import { graphql } from "gatsby"
import SchoolCard from "../components/schools/SchoolCard"
import Container from "../components/structural/Container"
import { Link } from "gatsby"

import styled from "styled-components"

const Card = styled.div`
  max-width: 900px;
  width: calc(90% - 32px);
  background-color: white;
  border-radius: 10px;
  margin: auto;
  align-items: center;
  padding: 32px;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
`

const Button = styled(Link)`
  margin-top: 16px;
  border: 1px solid #a2a2a2;
  transition-duration: 0.3s;
  border-radius: 50px;
  width: 160px;
  padding: 8px 8px 8px;
  text-decoration: none;
  cursor: pointer;
  background-color: #fff;
  z-index: 1;

  align-self: center;
  text-align: center;

  :hover {
    background-color: #a2a2a2 !important;
    color: white;
  }
`

export default function Education({ data }) {
  return (
    <SubLayout>
      <Container heading="education.heading">
        <Card>
          <SchoolCard
            img={data.quiz.childImageSharp.fluid}
            h={<FormattedMessage id={"education.card0.h"} />}
            p={<FormattedMessage id={"education.card0.p"} />}
          >
            <Button to="/quiz">
              <FormattedMessage id="Card.btn" />
            </Button>
          </SchoolCard>
          <SchoolCard
            img={data.cake.childImageSharp.fluid}
            h={<FormattedMessage id={"education.card1.h"} />}
            p={<FormattedMessage id={"education.card1.p"} />}
          >
            <Button to="/booklet">
              <FormattedMessage id="Card.btn" />
            </Button>
          </SchoolCard>
        </Card>
      </Container>
    </SubLayout>
  )
}

export const query = graphql`
  query {
    quiz: file(relativePath: { eq: "education/quiz.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    cake: file(relativePath: { eq: "bookletblog.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    congress: file(relativePath: { eq: "fundraising/congress.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
